import React from 'react'
import { graphql } from 'gatsby'
import tw from 'twin.macro'
// import contentParser from 'gatsby-wpgraphql-inline-images'

import Layout from '../../components/Layout'
import SEO from '../../components/Seo'

import { H1, H2, H3 } from '../../components/Headings'
import { ALink } from '../../components/A'
import Container from '../../components/Container'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import Img from 'gatsby-image'
import { ButtonLink } from '../../components/Button'
const PageTemplate = ({ data }) => {
  const page = data.allWpPage.nodes[0]

  const { hero, specializedPests, footerCta } = page.specializedPests

  const featuredPests = specializedPests.slice(0, 3)

  const remainingPests =
    specializedPests.length > 3 ? specializedPests.slice(3) : []

  return (
    <>
      <SEO />
      <Layout headerType="commercial">
        <section tw="relative">
          <div tw="overflow-hidden h-full w-full absolute z-0">
            {hero?.background?.localFile && (
              <Img
                fluid={hero.background.localFile.childImageSharp.fluid}
                style={{ position: 'absolute' }}
                css={[
                  tw`min-w-full min-h-full max-w-full max-h-full transform -translate-x-1/2 -translate-y-1/2 opacity-75`,
                  'z-index: -1; top:50%;left:50%;',
                ]}
              />
            )}

            <div
              css={[
                tw`absolute w-full h-full z-10 opacity-75`,
                `
                                background-color: white;
                                background-image: linear-gradient(145deg, #ffffff 41%, rgba(255, 255, 255, 0.75) 55%, rgba(128, 128, 128, 0) 83%);
                                @media (min-width: 1023.59px){
                                background-color: transparent;
                                }`,
              ]}
            />
          </div>
          <Container tw="relative py-20 z-20">
            <div tw="text-gray-700 lg:(w-1/2 )">
              <H1 tw="text-5xl">{hero.headline}</H1>
              <p tw="text-2xl font-bold mb-5">{hero.subHeadline}</p>
              <p tw="text-xl leading-10 mb-10 ">{hero.paragraph}</p>
              <ButtonLink to="/commercial-quote" large>
                <span tw="mr-3">Get a Quote</span>
                <FontAwesomeIcon icon={faChevronRight} />
              </ButtonLink>
            </div>
          </Container>
        </section>

        {/* Hero Section */}

        {/* Featured Pests */}
        {Array.isArray(featuredPests) && (
          <section tw="pt-20">
            <Container
              css={[
                tw`flex flex-col content-center justify-center lg:(grid grid-cols-3 gap-8) pb-10 border-b-2 border-gray-50`,
              ]}
            >
              {featuredPests.map(({ pestHeadline, shortDescription, pest }) => (
                <>
                  <div tw="flex flex-col col-span-1 overflow-x-hidden mb-10">
                    <ALink to={`/learn/${pest.slug}`}>
                      <div tw="w-2/3 max-w-sm mx-auto mb-5 lg:(h-48) xl:(h-64)">
                        <Img
                          fluid={
                            pest.featuredImage.node.localFile.childImageSharp
                              .fluid
                          }
                        />
                      </div>

                      <H3 tw="text-center text-blue-800">
                        {pestHeadline || pest.title}
                      </H3>
                    </ALink>
                    <p tw="text-center text-xl text-gray-800">
                      {shortDescription}
                    </p>
                  </div>
                </>
              ))}
            </Container>
          </section>
        )}

        {/* Other Specialized Pests */}
        {Array.isArray(remainingPests) && (
          <section tw="py-20">
            <Container>
              <div tw="mb-20 flex justify-center">
                <H2>Other Specialized Pests</H2>
              </div>
              <div tw="grid grid-cols-1 md:(grid-cols-2 gap-16) lg:(grid-cols-4)">
                {remainingPests.map(
                  ({ pestHeadline, shortDescription, pest }, index) => (
                    <>
                      {/* Add a horizontal rule before the new line */}
                      {!(index % 2) && index !== 0 && (
                        <hr
                          css={[
                            tw`hidden md:(flex) lg:(hidden) col-span-2 border-t-2 border-gray-50 mb-10`,
                            !(index % 4) && tw`lg:(flex col-span-4)`,
                          ]}
                        />
                      )}
                      <div tw="flex flex-col  col-span-1 overflow-x-hidden mb-20 lg:(mb-10)">
                        <ALink to={`learn/${pest.slug}`}>
                          <div tw="w-1/2 max-w-sm mx-auto mb-10 md:(h-24 mb-20) lg:(mb-10)">
                            <Img
                              fluid={
                                pest.featuredImage.node.localFile
                                  .childImageSharp.fluid
                              }
                            />
                          </div>

                          <H3 tw="text-center text-blue-800">
                            {pestHeadline || pest.title}
                          </H3>
                        </ALink>
                        <p tw="text-left text-xl text-gray-800">
                          {shortDescription}
                        </p>
                      </div>
                    </>
                  )
                )}
              </div>
            </Container>
          </section>
        )}

        {/* Footer CTA */}
        <section tw="py-16" aria-label="Get Your Free Estimate">
          <Container>
            {/* Footer CTA */}
            <div tw="text-center content-center pt-10">
              <H2 tw="text-5xl">{footerCta.headline}</H2>
              <p tw="text-lg leading-relaxed text-gray-800 lg:(px-10 mx-10) xl:(px-24 mx-24) mb-8">
                {footerCta.paragraph}
              </p>

              <ButtonLink to="/quote" large>
                <span tw="mr-3">{footerCta.ctaButtonText}</span>
                <FontAwesomeIcon icon={faChevronRight} />
              </ButtonLink>
            </div>
          </Container>
        </section>
      </Layout>
    </>
  )
}

export default PageTemplate

export const query = graphql`
  query($id: String!) {
    allWpPage(filter: { id: { eq: $id } }) {
      nodes {
        title
        slug
        specializedPests {
          hero: specializedPestServicesHero {
            headline: specializedPestServicesHeadline
            paragraph: specializedPestServicesHeroParagraph
            background: specializedPestHeroBackground {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          footerCta: specializedPestServicesFooterCta {
            headline: specializedPestServicesFooterCtaHeadline
            paragraph: specializedPestServicesFooterCtaParagraph
            ctaButtonText: specializedPestServicesFooterCtaButtonText
          }
          specializedPests {
            pestHeadline
            shortDescription
            pest {
              ... on WpPest {
                id
                title
                slug
                featuredImage {
                  node {
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 800) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                        fixed {
                          aspectRatio
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
